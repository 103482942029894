import { SchemaType } from "../../schema/generated/schema";

const SchemaTypeName = (type: SchemaType) => {
  switch (type) {
    case SchemaType.Graphql:
      return "GraphQL (not federated)";
    case SchemaType.GraphqlFederated:
      return "Federated GraphQL";
    case SchemaType.Openapi:
      return "REST (OpenAPI)";
    case SchemaType.Asyncapi:
      return "Message-based (AsyncAPI)";
    case SchemaType.Inactive:
      return "Inactive (removed)";
    default:
      throw new Error("Unexpected enumeration value.");
  }
};

export default SchemaTypeName;
