/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
// eslint-disable-next-line
import React from "react";
import { Spinner, theme, Button, Flex } from "@xometry/xometry_loft";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { jsx, Global, css } from "@emotion/core";
import { Localized } from "@xometry/react-localization";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Switch } from "react-router";
import { HashRouter } from "react-router-dom";
import Header from "./Header";
import { useLatestServiceListQuery } from "../../schema/generated/schema";
import GraphSummary from "./GraphSummary";
import ProvidedAPIDetails from "./ProvidedAPIDetails";

const httpLink = createHttpLink({
  uri: "https://api-catalog.xometry.net/api/graphql",
});

const spinning = css`
  animation-name: spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const HomePage = () => {
  const { loading, error, data, refetch } = useLatestServiceListQuery({
    notifyOnNetworkStatusChange: true,
  });

  let bodyContent: JSX.Element | string | null = null;
  if (data) {
    bodyContent = <GraphSummary graph={data.latestAPIGraph!} />;
  } else if (error) {
    bodyContent = `Error loading data: ${error}.`;
  }

  return (
    <div>
      <h1 id="page-header">
        Services and APIs
        <Button
          kind="gray"
          onClick={() => refetch()}
          css={css`
            margin-left: 5px;
          `}
        >
          <i
            className="fas fa-sync"
            title="Refresh Data"
            css={loading && spinning}
          />
        </Button>
      </h1>
      {bodyContent}
    </div>
  );
};

const MainRouter = () => {
  const { getAccessTokenSilently } = useAuth0();

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route
          path="/latest/:serviceName/api/:apiIdentifier"
          exact
          component={ProvidedAPIDetails}
        />
        <Route>
          <h1>Not Found</h1>
          <p>The page you asked for could not be found.</p>
        </Route>
      </Switch>
    </ApolloProvider>
  );
};

const App = () => {
  const {
    isLoading: authLoading,
    error: authError,
    isAuthenticated,
    loginWithPopup,
  } = useAuth0();

  let mainBody: JSX.Element;
  if (authLoading) {
    mainBody = (
      <div>
        <Spinner scale="panel" />
      </div>
    );
  } else if (authError) {
    mainBody = <div>Authentication error: {authError.toString()}</div>;
  } else if (!isAuthenticated) {
    mainBody = (
      <div>
        <h1 id="page-header">
          <Localized id="please-log-in">Please Log In</Localized>
        </h1>
        <Button onClick={loginWithPopup}>Log In</Button>
      </div>
    );
  } else {
    mainBody = <MainRouter />;
  }

  return (
    <>
      <Global
        styles={css`
          body {
            margin: 0;
            padding: 0;
            font-size: ${theme.fontSizes[1]};
            line-height: ${theme.lineHeights[2]};
            font-family: "Open Sans", Helvetica, Arial, sans-serif;
          }
        `}
      />
      <HashRouter>
        <Flex
          flexDirection="column"
          css={css`
            height: 100vh;
            width: 100vw;
          `}
        >
          <Header />
          <Flex
            flex="1"
            flexDirection="column"
            css={css`
              overflow-y: auto;
              padding: 5px;
            `}
          >
            {mainBody}
          </Flex>
        </Flex>
      </HashRouter>
    </>
  );
};

export default App;
