/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  GraphQLDateTime: any;
};

export type ApiGraph = {
  readonly __typename?: 'APIGraph';
  readonly services: ReadonlyArray<Service>;
  readonly serviceByName: Maybe<Service>;
};


export type ApiGraphServiceByNameArgs = {
  name: Scalars['String'];
};

export type ConsumedApi = {
  readonly __typename?: 'ConsumedAPI';
  readonly consumingServiceName: Maybe<Scalars['String']>;
  readonly consumedServiceName: Maybe<Scalars['String']>;
  readonly consumedAPIIdentifier: Maybe<Scalars['String']>;
  /**
   * The schema type of a consumed API must be compatible with the provided API.
   * 
   * This must never be GRAPHQL_FEDERATED: to consume a federated schema, you must consume
   * the federation service, not the underlying service that is federated.
   */
  readonly schemaType: SchemaType;
  /**
   * The schema document. This may only be null of the schema type is INACTIVE.
   * 
   * Note: this schema must be compatible with but doesn't need to exactly match the provided schema. For example,
   * an API may provide three GraphQL or OpenAPI fields, but if a consumer only uses one of those, it should only
   * list that one in its consumed API schema.
   */
  readonly schema: Maybe<Scalars['String']>;
  readonly lastUpdated: Maybe<Scalars['GraphQLDateTime']>;
  /** A history of changes to this API. The most recent change will be first in the list. */
  readonly history: ReadonlyArray<ConsumedApi>;
};

export type ConsumedApiInput = {
  readonly consumedServiceName: Scalars['String'];
  readonly consumedAPIIdentifier: Scalars['String'];
  /** The schema type for a service update must not be INACTIVE. Services will be marked inactive automatically. */
  readonly type: SchemaType;
  readonly schema: Scalars['String'];
};

export enum Environment {
  Production = 'PRODUCTION',
  Stage = 'STAGE'
}


export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly updateService: SchemaCheckResult;
};


export type MutationUpdateServiceArgs = {
  environment: Environment;
  input: ServiceUpdateInput;
};

export type ProvidedApi = {
  readonly __typename?: 'ProvidedAPI';
  /** A back-reference from an API to the service that provides it. */
  readonly serviceName: Maybe<Scalars['String']>;
  /**
   * An identifier for this API.
   * 
   * A service may provide multiple APIs, for example a GraphQL API as well as a message-passing interface. Each API
   * will have an identifier string. This may be as simple as "graphql" or "asyncapi", or in more complex cases it may be
   * "asyncapi-v2" for versioned APIs.
   */
  readonly APIIdentifier: Scalars['String'];
  readonly schemaType: SchemaType;
  /** The schema document. This may only be null if the schema type is INACTIVE. */
  readonly schema: Maybe<Scalars['String']>;
  readonly lastUpdated: Maybe<Scalars['GraphQLDateTime']>;
  /**
   * Version information about this API. This will often be null for services which
   * deploy from the main branch instead of from tags.
   */
  readonly version: Maybe<Scalars['String']>;
  /**
   * Revision information for the current version of this API. This is an arbitrary
   * string but should be a github commit link if available.
   */
  readonly revision: Maybe<Scalars['String']>;
  /** A history of changes to this API. The most recent prior change will be first in the list. */
  readonly history: ReadonlyArray<ProvidedApi>;
};

export type ProvidedApiInput = {
  readonly APIIdentifier: Scalars['String'];
  /** The schema type for a service update must not be INACTIVE. Services will be marked inactive automatically. */
  readonly type: SchemaType;
  readonly schema: Scalars['String'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly latestAPIGraph: Maybe<ApiGraph>;
  readonly checkServiceUpdate: SchemaCheckResult;
};


export type QueryLatestApiGraphArgs = {
  environment?: Environment;
};


export type QueryCheckServiceUpdateArgs = {
  environment: Environment;
  input: ServiceUpdateInput;
};

export type SchemaCheckMessage = {
  readonly __typename?: 'SchemaCheckMessage';
  readonly message: Scalars['String'];
  readonly providerServiceName: Maybe<Scalars['String']>;
  readonly APIIdentifier: Maybe<Scalars['String']>;
  readonly consumerServiceName: Maybe<Scalars['String']>;
};

export type SchemaCheckResult = {
  readonly __typename?: 'SchemaCheckResult';
  readonly checkPassed: Scalars['Boolean'];
  readonly errors: ReadonlyArray<SchemaCheckMessage>;
  readonly warnings: ReadonlyArray<SchemaCheckMessage>;
  readonly messages: ReadonlyArray<SchemaCheckMessage>;
  readonly resultGraph: Maybe<ApiGraph>;
};

export enum SchemaType {
  /** A GraphQL schema. Standalone, not federated into our shared API. */
  Graphql = 'GRAPHQL',
  /** A GraphQL schema, federated into our shared API. */
  GraphqlFederated = 'GRAPHQL_FEDERATED',
  /** An OpenAPI (swagger) REST schema. */
  Openapi = 'OPENAPI',
  /** An AsyncAPI message-passing schema. */
  Asyncapi = 'ASYNCAPI',
  /** A marker for a schema that is no longer active. */
  Inactive = 'INACTIVE'
}

export type Service = {
  readonly __typename?: 'Service';
  /**
   * The name of this service. This should match the git repository name and the name in the Xometry
   * service catalog.
   */
  readonly name: Scalars['String'];
  /** Get all the APIs provided by this service. */
  readonly providedAPIs: Maybe<ReadonlyArray<ProvidedApi>>;
  /** Get a particular API provided by this service. */
  readonly providedAPIByIdentifier: Maybe<ProvidedApi>;
  /** Get all the APIs consumed by this service. */
  readonly consumedAPIs: Maybe<ReadonlyArray<ConsumedApi>>;
  /** Get all the APIs consumed by this service from a particular "target" service. */
  readonly consumedAPIsByService: Maybe<ReadonlyArray<ConsumedApi>>;
  /** Get a particular consumed API. */
  readonly consumedAPIsByServiceAndIdentifier: Maybe<ConsumedApi>;
};


export type ServiceProvidedApiByIdentifierArgs = {
  identifier: Scalars['String'];
};


export type ServiceConsumedApIsByServiceArgs = {
  serviceName: Scalars['String'];
};


export type ServiceConsumedApIsByServiceAndIdentifierArgs = {
  serviceName: Scalars['String'];
  apiIdentifier: Scalars['String'];
};

export type ServiceUpdateInput = {
  readonly serviceName: Scalars['String'];
  readonly providedAPIs: ReadonlyArray<ProvidedApiInput>;
  readonly consumedAPIs: ReadonlyArray<ConsumedApiInput>;
  /** Optional marker for the current version of this service. */
  readonly version: Maybe<Scalars['String']>;
  /**
   * Optional but recommended marker for the current revision of this services.
   * This SHOULD be a github revision link if available.
   */
  readonly revision: Maybe<Scalars['String']>;
};

export type ProvidedApiDetailsFragment = (
  { readonly __typename?: 'ProvidedAPI' }
  & Pick<ProvidedApi, 'APIIdentifier' | 'schemaType' | 'schema'>
);

export type ConsumedApiDetailsFragment = (
  { readonly __typename?: 'ConsumedAPI' }
  & Pick<ConsumedApi, 'consumedServiceName' | 'consumedAPIIdentifier' | 'schemaType' | 'schema'>
);

export type GraphDetailsFragment = (
  { readonly __typename?: 'APIGraph' }
  & { readonly services: ReadonlyArray<(
    { readonly __typename?: 'Service' }
    & Pick<Service, 'name'>
    & { readonly providedAPIs: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ProvidedAPI' }
      & ProvidedApiDetailsFragment
    )>>, readonly consumedAPIs: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ConsumedAPI' }
      & ConsumedApiDetailsFragment
    )>> }
  )> }
);

export type LatestServiceListQueryVariables = {};


export type LatestServiceListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly latestAPIGraph: Maybe<(
    { readonly __typename?: 'APIGraph' }
    & GraphDetailsFragment
  )> }
);

export const ProvidedApiDetailsFragmentDoc = gql`
    fragment ProvidedAPIDetails on ProvidedAPI {
  APIIdentifier
  schemaType
  schema
}
    `;
export const ConsumedApiDetailsFragmentDoc = gql`
    fragment ConsumedAPIDetails on ConsumedAPI {
  consumedServiceName
  consumedAPIIdentifier
  schemaType
  schema
}
    `;
export const GraphDetailsFragmentDoc = gql`
    fragment GraphDetails on APIGraph {
  services {
    name
    providedAPIs {
      ...ProvidedAPIDetails
    }
    consumedAPIs {
      ...ConsumedAPIDetails
    }
  }
}
    ${ProvidedApiDetailsFragmentDoc}
${ConsumedApiDetailsFragmentDoc}`;
export const LatestServiceListDocument = gql`
    query latestServiceList {
  latestAPIGraph {
    ...GraphDetails
  }
}
    ${GraphDetailsFragmentDoc}`;

/**
 * __useLatestServiceListQuery__
 *
 * To run a query within a React component, call `useLatestServiceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestServiceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestServiceListQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestServiceListQuery(baseOptions?: Apollo.QueryHookOptions<LatestServiceListQuery, LatestServiceListQueryVariables>) {
        return Apollo.useQuery<LatestServiceListQuery, LatestServiceListQueryVariables>(LatestServiceListDocument, baseOptions);
      }
export function useLatestServiceListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestServiceListQuery, LatestServiceListQueryVariables>) {
          return Apollo.useLazyQuery<LatestServiceListQuery, LatestServiceListQueryVariables>(LatestServiceListDocument, baseOptions);
        }
export type LatestServiceListQueryHookResult = ReturnType<typeof useLatestServiceListQuery>;
export type LatestServiceListLazyQueryHookResult = ReturnType<typeof useLatestServiceListLazyQuery>;
export type LatestServiceListQueryResult = Apollo.QueryResult<LatestServiceListQuery, LatestServiceListQueryVariables>;