/* eslint-disable react/jsx-one-expression-per-line */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
// eslint-disable-next-line
import React from "react";
import { jsx, css } from "@emotion/core";
import { Link } from "react-router-dom";
import { theme } from "@xometry/xometry_loft";

import { GraphDetailsFragment } from "../../schema/generated/schema";

interface ServiceRowProps {
  service: GraphDetailsFragment["services"][number];
}

const ServiceRow = ({ service }: ServiceRowProps) => {
  return (
    <tr>
      <td>{service.name}</td>
      <td>
        {service.providedAPIs &&
          service.providedAPIs.map(api => (
            <Link
              to={`/latest/${service.name}/api/${api.APIIdentifier}`}
              key={api.APIIdentifier}
            >
              {api.APIIdentifier}
            </Link>
          ))}
      </td>
      <td>
        {service.consumedAPIs &&
          service.consumedAPIs.map(api => (
            <Link
              to={`latest/${service.name}/consumes/${api.consumedServiceName}/${api.consumedAPIIdentifier}`}
              key={`${api.consumedServiceName}:${api.consumedAPIIdentifier}`}
            >
              {api.consumedServiceName}:{api.consumedAPIIdentifier}
            </Link>
          ))}
      </td>
    </tr>
  );
};

interface GraphSummaryProps {
  graph: GraphDetailsFragment;
}

const GraphSummary = ({ graph }: GraphSummaryProps) => {
  return (
    <table
      css={css`
        border: 1px solid ${theme.colors.grays[1]};
        border-collapse: collapse;

        th {
          background-color: ${theme.colors.grays[1]};
          color: white;
          margin: 0;
          padding: 1px 3px;
        }

        td {
          margin: 0;
          padding: 1px 3px;
          border: 1px solid ${theme.colors.grays[1]};
        }
      `}
    >
      <thead>
        <tr>
          <th>Service</th>
          <th>Provides</th>
          <th>Consumes</th>
        </tr>
      </thead>
      <tbody>
        {graph.services.map(service => (
          <ServiceRow key={service.name} service={service} />
        ))}
      </tbody>
    </table>
  );
};

export default GraphSummary;
