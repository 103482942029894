/* eslint-disable react/jsx-one-expression-per-line */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
// eslint-disable-next-line
import React from "react";
import { jsx, css } from "@emotion/core";
import { useParams } from "react-router";
import { theme, Spinner, Flex, Box } from "@xometry/xometry_loft";
import GraphiQL from "graphiql";
import "graphiql/graphiql.min.css";
import { buildSchema } from "graphql";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

import {
  SchemaType,
  useLatestServiceListQuery,
} from "../../schema/generated/schema";
import SchemaTypeName from "./SchemaTypeName";

interface APIDetailsProps {
  serviceName: string;
  apiIdentifier: string;
}

const apiDetails = (schemaType: SchemaType, schema: string | null) => {
  if (schema === null || schemaType === SchemaType.Inactive) {
    return null;
  }

  if (
    schemaType === SchemaType.Graphql ||
    schemaType === SchemaType.GraphqlFederated
  ) {
    try {
      const parsedSchema = buildSchema(schema);
      return (
        <GraphiQL
          schema={parsedSchema}
          fetcher={() => ({
            errors: ["Live data fetching not implemented from API catalog."],
          })}
          docExplorerOpen
        />
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        "Error parsing graphql schema. Falling back to text display.",
        e
      );
    }
  }

  if (schemaType === SchemaType.Openapi) {
    const parsedSchema = JSON.parse(schema);
    return <SwaggerUI spec={parsedSchema} />;
  }

  return (
    <pre
      key="raw"
      css={css`
        border: 1px solid ${theme.colors.grays[1]};
        background-color: ${theme.colors.grays[5]};
        padding: 4px;
      `}
    >
      {schema}
    </pre>
  );
};

const APIDetailsBody = ({ serviceName, apiIdentifier }: APIDetailsProps) => {
  const { loading, error, data } = useLatestServiceListQuery();
  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <>
        <h1>
          Service: {serviceName} API: {apiIdentifier}
        </h1>
        <p>Error loading data: {error.toString()}.</p>
      </>
    );
  }

  const service = data?.latestAPIGraph?.services.find(
    s => s.name === serviceName
  );
  if (!service) {
    return (
      <>
        <h1>
          Service: {serviceName} API: {apiIdentifier}
        </h1>
        <p>Error: service not found!</p>
      </>
    );
  }

  const providedAPI = service.providedAPIs?.find(
    api => api.APIIdentifier === apiIdentifier
  );
  if (!providedAPI) {
    return (
      <>
        <h1>
          Service: {serviceName} API: {apiIdentifier}
        </h1>
        <p>Error: API not found!</p>
      </>
    );
  }

  return (
    <Flex flexDirection="column" flex="1">
      <h1>
        Service: {serviceName} API: {apiIdentifier} Type:{" "}
        {SchemaTypeName(providedAPI.schemaType)}
      </h1>
      <Box flex="1">
        {apiDetails(providedAPI.schemaType, providedAPI.schema)}
      </Box>
    </Flex>
  );
};

const APIDetails = () => {
  const { serviceName, apiIdentifier } = useParams() as APIDetailsProps;
  return (
    <APIDetailsBody serviceName={serviceName} apiIdentifier={apiIdentifier} />
  );
};

export default APIDetails;
