import "react-app-polyfill/ie11";
import "@xometry/xometry_loft/deps/fontawesome-pro/css/all.min.css";
import "@xometry/xometry_loft/deps/fonts.css";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "emotion-theming";
import { theme } from "@xometry/xometry_loft";
import AppLocalizedProvider from "@xometry/react-localization";
import { AppState, Auth0Provider } from "@auth0/auth0-react";

import App from "./components/App";
import history from "./components/App/history";

import "intl-pluralrules";

const onRedirectCallback = (appState: AppState): void => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const authDomain = "login.xometry.com";
const authClient = "7lYXQpilT1F2FNcIXlNLBC0JPcdzOT0v";
const authAudience = "api-catalog.xometry.net";

// To render other languages replace navigator.languages with fr-FR or pl
ReactDOM.render(
  <AppLocalizedProvider localesSupported={["en-US"]} ftlFileLocation="/lang/">
    <ThemeProvider theme={theme}>
      <Auth0Provider
        domain={authDomain}
        clientId={authClient}
        audience={authAudience}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <App />
      </Auth0Provider>
    </ThemeProvider>
  </AppLocalizedProvider>,
  document.getElementById("root")
);
